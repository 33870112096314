'use strict';

/* Video Controller */

angular.module('game').controller('headerCtrl', ['$scope', '$rootScope', 'audioService', 'i18nService', '$window', '$location', '$route', 'apiCall', 'globalService', 'gameSettings',
  function ($scope, $rootScope, audioService, i18nService, $window, $location, $route, apiCall, globalService, gameSettings) {

    var iconVolumeOff = $scope.contentURL + "images/common/v2/icons/icon-volume-off-41x41.png";
    var iconVolumeOn = $scope.contentURL + "images/common/v2/icons/icon-volume-on-41x41.png";

    var $timeout = globalService.angularServices.$timeout;

    $scope.config = gameSettings;
    $scope.iconVolume = iconVolumeOn;
    $scope.languages =  $scope.config.settings.languages;
    $scope.showMultilanguageSelector = $scope.languages.length > 1;

    function init() {
      if (Utils.isMobileOrTablet()) {
        $scope.volumeIcon = iconVolumeOff;
      }
      audioService.shouldPlay(false);

      globalService.userService.getUser().then(function (user) {
        audioService.shouldPlay(user.volume);
        if (!audioService.shouldBePlaying) {
          document.getElementById("volume").src = iconVolumeOff;
        }
        if ($scope.showMultilanguageSelector) {
          console.log("Current user language: ", user.language);
          // Set selected the current user language
          angular.element(".lang-icon[data-lang='" + user.language + "']").addClass('selected');
        }
      });

      hideMuteButtonOnChallengeView();

      $scope.showBackIndicator = false;
      hideBackIndicatorOnHomeView();

      $scope.$on("$destroy", function () {
        audioService.stop();
      });

    }

    $scope.toggleVolume = function () {
      if (Utils.isMobileOrTablet()) {
        return;
      }

      var volume = audioService.audioPlayer().getVolume();
      if (volume == 0 || volume == undefined) {
        apiCall.put("api/1/user/volume/enable").then(function (e) {
          document.getElementById("volume").src = iconVolumeOn;
          audioService.shouldPlay(true);
        }).catch(function (e) {
          // console.log("Error enabling volume");
        })
      } else {
        apiCall.put("api/1/user/volume/disable").then(function () {
          document.getElementById("volume").src = iconVolumeOff;
          audioService.stop();
        })
          .catch(function (e) {
            // console.log("Error disabling volume");
          })
      }
    };

    $scope.showHelp = function () {
      $scope.helpPopupAPI
        .setHeight(540)
        .open();
    };

    $scope.hideHelp = function () {
      $scope.helpPopupAPI.close();
    };

    $scope.logout = function() {
      globalService.logout();
    };

    $scope.home = function () {
      $location.path('/home')
    };

    $scope.selectLanguage = function (event, lang) {
      // todo: Show modal dialog to confirm language change
      var selectedElement = event.currentTarget;
      console.log('Updating user language at server, new language: ', lang);
      i18nService.getLocale(false, lang).then(function(i18n) { // Reload i18n from server
        // Overwrite all locale with new lang
        $rootScope.locale = i18n;
        angular.element(".lang-icon").removeClass('selected');
        angular.element(selectedElement).addClass('selected');

        globalService.userService.getUser(true); // Reload user info
        //$rootScope.$broadcast('languageChanged');
        //$location.path('/home');
        $window.location.reload();
      });
    };

    function hideBackIndicatorOnHomeView() {
      if ($location.path().indexOf("home") === -1) {
        $scope.showBackIndicator = true;
      }
      $rootScope.$on("$routeChangeSuccess", function ($event, next, current) {
        if (next.$$route !== undefined && next.$$route.originalPath.indexOf("home") === -1) {
          $scope.showBackIndicator = true;
        } else {
          $scope.showBackIndicator = false;
        }
      });

    }

    function hideMuteButtonOnChallengeView() {
      var muteButton = angular.element(".header-icons .volume")[0];

      if ($location.path().indexOf("challenge") !== -1) {
        muteButton.style.display = "none";
      } else {
        muteButton.style.display = "inline-block";
      }

      $rootScope.$on("$routeChangeSuccess", function ($event, next, current) {
        if (next.$$route !== undefined && next.$$route.originalPath.indexOf("challenge") !== -1) {
          $timeout(function() {
            muteButton.style.display = "none";
          }, 100);
        } else {
          $timeout(function() {
            muteButton.style.display = "inline-block";
          }, 100);
        }
      });
    }

    init();
  }
])
