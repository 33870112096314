'use strict';

angular.module('game').controller('registrationCtrl', ['$scope', 'i18nService', 'globalService', '$location', '$routeParams', '$timeout', '$interval', 'gameSettings', 'config', 'apiCall',
  function ($scope, i18nService, globalService, $location, $routeParams, $timeout, $interval, gameSettings, config, apiCall) {

    var init = function() {

      /* --- User Registration section --- */
      $scope.registrationMessage = null;

      $scope.registrationModel = {
        nickname: null,
        frontName: null,
        email: null,
        employeeId: null,
        password1: null,
        password2: null,
        secretKey: null
      };

    };

    /***** Controller Methods *******/

    /**
     * Highlights
     */
    $scope.highlightError = function() {
      $interval(function() {
        angular.element('.b-login .error-message').toggleClass('highlight').toggleClass('bounce-fast');
      }, 600, 2);
    };

    /**
     * Tries to login if the user press Enter
     * @param e Event
     * @param method Function from the controller to be invoked
     */
    $scope.keyup = function (e, method) {
      if (e.keyCode == 13) {
        $scope[method]();
      }
      return true;
    };

    /**
     * Validate sign up form locally
     */
    $scope.validateRegistrationData = function() {
      if ($scope.registrationModel.password1.length > 2 && $scope.registrationModel.password2.length > 2) {
        if ($scope.registrationModel.password1 !== $scope.registrationModel.password2) {
          // Passwords don't match
          i18nService.getLocale().then(function(decalogue) {
            $scope.registrationMessage = decalogue['front.login.error.passwordNotMatching'];
            $scope.registrationSuccess = false;
            $scope.highlightError();
          });
          return false;
        }
        $scope.registrationMessage = "";
        return true;
      }
      $scope.registrationMessage = "";
      return false;
    };

    /**
     * Informs the user if the repeat password is valid
     */
    $scope.validateRegistrationForm = function($e) {
      if ($scope.checkingPasswordMatch) {
        $scope.checkingPasswordMatch = false;
        $scope.validateRegistrationData();
      } else {
        $timeout.cancel($scope.checkingPasswordMatchTimeout);
        $scope.checkingPasswordMatchTimeout = $timeout(function() {
          $scope.checkingPasswordMatch = true;
          $scope.validateRegistrationForm();
        }, 1000);
      }

      return true;
    };

    /**
     * Do the actual registration call to the API
     */
    $scope.register = function() {
      if ($scope.validateRegistrationData()){
        // Make null empty values
        Object.keys($scope.registrationModel).forEach(function (key, index) {
          if ($scope.registrationModel[key] === "" || $scope.registrationModel[key] === " ") {
            $scope.registrationModel[key] = null;
          }
        });

        apiCall.post('api/1/pub/account/registration', {
          nickname: $scope.registrationModel.nickname,
          frontName: $scope.registrationModel.frontName,
          email: $scope.registrationModel.email,
          password: $scope.registrationModel.password1,
          secretKey: $scope.registrationModel.secretKey
        }, {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'text/plain'
        }, {
          handleErrors: {
            400: true,
            500: true
          },
          handleAsForm: false
        }).then(function (response) {
          // Success
          if (response.status === 201) {
            // User created
            i18nService.getLocale().then(function (locale) {
              $scope.registrationMessage = locale['front.signup.success'];
            });
          }
          // Show success message and email verification advise

        }, function (reason) {

          if (reason.status === 400) {
            // Failed, show message from backend or get i18n default message
            i18nService.getLocale().then(function (locale) {
              $scope.registrationMessage = locale['front.signup.error'];
              if (reason.data && reason.data !== "") {
                $scope.registrationMessage += ", " + reason.data;
              }
              $scope.highlightError();
            });
          }
        });
      }

      return false; // do not make the form redirection
    };

    init();

  }
]);
